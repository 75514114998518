import React, { createContext, useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import Wrapper from './Wrapper'
import Renderer from './Renderer'
import ScrollStopper from './ScrollStopper'
import Share from './Share'
import './App.css'

// This higher-order component correctly formats Sentry transaction names for routes.
const SentryRoute = Sentry.withSentryRouting(Route)

const isRunningStandalone = () => {
  return (
    window.navigator.standalone === true ||
    window.matchMedia('(display-mode: standalone)').matches
  )
}

export const AppContext = createContext(null)

const App = ({ app, routerHistory }) => {
  useEffect(() => {
    if (isRunningStandalone()) {
      document.body.classList.add('standalone')
    }

    return () => {
      if (isRunningStandalone()) {
        document.body.classList.remove('standalone')
      }
    }
  }, [])

  return (
    <Router history={routerHistory}>
      <AppContext.Provider value={{ app }}>
        <Wrapper>
          <ScrollStopper />
          <Switch>
            <SentryRoute exact path="/" component={Share} />
            <SentryRoute path="/preview/:appId" component={Renderer} />
            <SentryRoute path="/:appId" component={Share} />
          </Switch>
        </Wrapper>
      </AppContext.Provider>
    </Router>
  )
}

export default App
