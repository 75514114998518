/**
 * In the context of the web-preview application,
 * only show install prompt if:
 * - app is legacy mobile
 *
 * @param {import("types").App} [app]
 * @returns {boolean}
 */
export const shouldPrompt = app => {
  if (!app) {
    return false
  }

  const hasMagicLayout = Boolean(app.magicLayout)
  const primaryPlatform = app.primaryPlatform

  if (hasMagicLayout) {
    return false
  }

  if (primaryPlatform === 'mobile') {
    return true
  }

  return false
}
