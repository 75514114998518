import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { DeviceType, DeviceBreakpoint, DeviceWidth } from '@adalo/constants'
import classNames from 'classnames'

import Button from 'components/Shared/Button'
import enterFullscreenIcon from 'components/Shared/Icon/icons/enter-fullscreen.svg'
import closeFullscreenIcon from 'components/Shared/Icon/icons/close-fullscreen.svg'
import { ReactComponent as AdaloSmallLogo } from 'components/Shared/Icon/icons/small-logo.svg'
import { ReactComponent as DesktopIcon } from 'components/Shared/Icon/icons/desktop.svg'
import { ReactComponent as TabletIcon } from 'components/Shared/Icon/icons/tablet.svg'
import { ReactComponent as MobileIcon } from 'components/Shared/Icon/icons/mobile.svg'

import Loading from 'components/Shared/Loading'
import { AppContext } from 'components/App'
import PreviewType from 'utils/previewtype'

import Renderer from '../Renderer'

import Frame from './Frame'
import { WEB, DEFAULT_SIZE } from './SizeMenu'
import AppDetails from './AppDetails'
import Attribution from './Attribution'
import Stripe from './Stripe'
import Spacer from './Spacer'

import './Share.css'
import './WrappedSelect.css'

let deviceTypes = [
  { type: DeviceType.MOBILE, icon: MobileIcon },
  { type: DeviceType.TABLET, icon: TabletIcon },
  { type: DeviceType.DESKTOP, icon: DesktopIcon },
]

const MOBILE_BEZEL_WIDTH = 44
const TABLET_BEZEL_WIDTH = 78

const sizes = {
  mobile: {
    width: DeviceWidth.MOBILE_DEFAULT_WIDTH + MOBILE_BEZEL_WIDTH,
    height: 841,
    offset_top: 40,
    offset_bottom: 40,
    os: 'ios',
  },
  tablet: {
    width: DeviceWidth.TABLET_DEFAULT_WIDTH + TABLET_BEZEL_WIDTH,
    height: 1196,
    offset_top: 0,
    offset_bottom: 20,
    os: 'ios',
  },
  desktop: {
    os: 'web',
    width: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
    offset_top: 0,
    offset_bottom: 0,
  },
}

const Preview = props => {
  const [size, setSize] = useState(null)
  const [activeDevice, setActiveDevice] = useState(null)
  const [windowSize, setWindowSize] = useState(getWindowSize())
  // TODO: Remove this once we have a better way to handle fullscreen mode
  const [disableFullscreen] = useState(true)
  const [showHeader, setShowHeader] = useState(
    !localStorage.getItem('fullscreenEnabled')
  )

  const { app } = useContext(AppContext)

  const handleDeviceChange = device => {
    setActiveDevice(device)
    setSize(sizes[device])
    localStorage.setItem(`activeDevice-${app?.id}`, device)
  }

  const { hasResponsivePreviewer } = useFlags()
  const { hasNewMobileOnlyApp } = useFlags()

  const mobileOnly =
    hasNewMobileOnlyApp && app?.webSettings?.layoutMode === 'mobile'

  const { match } = props

  useEffect(() => {
    if (hasResponsivePreviewer && app?.primaryPlatform === 'responsive') {
      let lastDevice = localStorage.getItem(`activeDevice-${app?.id}`)

      if (!lastDevice) {
        const initialScreenId = app?.launchComponentId ?? app?.authComponentId
        const initialScreenWidth = app?.components[initialScreenId].width

        if (initialScreenWidth <= DeviceBreakpoint.MOBILE_BREAKPOINT) {
          lastDevice = PreviewType.Mobile
        } else if (initialScreenWidth <= DeviceBreakpoint.TABLET_BREAKPOINT) {
          lastDevice = PreviewType.Tablet
        } else {
          lastDevice = PreviewType.Desktop
        }
      }

      setSize(sizes[lastDevice])
      setActiveDevice(lastDevice)
    }

    if (mobileOnly) {
      deviceTypes = []
    }
  }, [app, hasResponsivePreviewer])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const appId = app.id
  const PREVIEW_BANNER_HEIGHT = showHeader ? 64 : 0

  const showAdaloAttribution = getShowAdaloAttribution(app)
  const previewType = getPreviewType(app)

  if (!size) {
    if (previewType === 'web') {
      setSize(WEB)
    } else {
      setSize(DEFAULT_SIZE)
    }
  }

  if (hasResponsivePreviewer && app.primaryPlatform === 'responsive') {
    const renderResponsivePreviewer = () => {
      return (
        <div
          className={classNames('web-renderer-wrapper--responsive', {
            framed: activeDevice !== PreviewType.Desktop,
          })}
        >
          <Frame
            appId={appId}
            size={sizes[activeDevice]}
            previewType={previewType}
            responsivePreviewer={hasResponsivePreviewer}
            activeDevice={activeDevice}
            isFullscreen={!showHeader}
          />
        </div>
      )
    }

    const renderHeader = () => {
      return showHeader ? (
        <div className="web-preview-header web-preview-header--responsive">
          <div>
            <AdaloSmallLogo />
            <h1 className="app-name">{app.name}</h1>
          </div>
          <div
            className={classNames('device-type-selector', {
              'align-center': disableFullscreen,
            })}
          >
            {deviceTypes.map(({ type, icon: Icon }) => (
              <Button
                key={type}
                className="device-type-button"
                backgroundColor={activeDevice === type && '#00A996'}
                onClick={() => handleDeviceChange(type)}
              >
                <Icon />
              </Button>
            ))}
          </div>
          {!disableFullscreen ? (
            <div className="fullscreen-wrapper">
              <Button
                className="fullscreen-button fullscreen-button-enter"
                onClick={() => {
                  setShowHeader(false)
                  localStorage.setItem('fullscreenEnabled', true)
                }}
                backgroundColor="#00000000"
              >
                <img src={enterFullscreenIcon} alt="Enter fullscreen" />
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="fullscreen-wrapper--floating">
          <Button
            className="fullscreen-button fullscreen-button-exit"
            onClick={() => {
              setShowHeader(true)
              localStorage.removeItem('fullscreenEnabled')
            }}
          >
            <img src={closeFullscreenIcon} alt="Exit fullscreen" />
          </Button>
        </div>
      )
    }

    return (
      <div
        className={classNames({
          'fullscreen-enabled': !showHeader,
        })}
      >
        {renderHeader()}
        {activeDevice ? renderResponsivePreviewer() : <Loading expanded />}
      </div>
    )
  }

  if (size === WEB) {
    return (
      <div>
        <div className="web-preview-header">
          <Stripe className="top-stripe" branding={app.branding} />
          <div className="web-preview-header-logo" />
          <Spacer />
        </div>
        <div className="web-renderer-wrapper">
          <Renderer
            match={match}
            offsetTop={PREVIEW_BANNER_HEIGHT}
            previewType={previewType}
          />
        </div>
        {showAdaloAttribution && <Attribution />}
      </div>
    )
  }

  if (windowSize.innerWidth < 890) {
    return <Renderer analytics match={match} previewType={previewType} />
  }

  return (
    <React.Fragment>
      <Stripe branding={app.branding} className="top-stripe" />
      <div className="preview">
        <AppDetails app={app} />
        <Frame appId={appId} size={size} previewType={previewType} />
      </div>
      {showAdaloAttribution && <Attribution />}
    </React.Fragment>
  )
}

const hasWebSettings = app => Boolean(app.magicLayout && app.webSettings)

const isValidPreviewType = previewType =>
  ['mobile', 'web'].includes(previewType)

const getPreviewType = app => {
  const { webSettings, primaryPlatform } = app

  if (webSettings?.layoutMode === 'mobile') {
    return 'mobile'
  }

  if (!hasWebSettings(app)) {
    // Legacy apps
    return primaryPlatform
  }

  const settingsPreviewType = webSettings?.previewType
  // Default to web when invalid/unset
  const previewType = isValidPreviewType(settingsPreviewType)
    ? settingsPreviewType
    : 'web'

  return previewType
}

const getShowAdaloAttribution = app => {
  const { showBranding, primaryPlatform } = app

  const showAttributionOnMobile = showBranding ?? true
  const showAttributionWebSetting =
    app?.webSettings?.showAdaloAttribution ?? false

  // Mobile apps keep using legacy 'showBranding' property
  const showAdaloAttribution =
    primaryPlatform === 'mobile'
      ? showAttributionOnMobile
      : showAttributionWebSetting

  // Attribution is shown in AppFooter if cloning is enabled
  const cloningEnabled = app?.visibility

  return showAdaloAttribution && !cloningEnabled
}

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default withRouter(Preview)
