import React, { useState } from 'react'
import axios from 'axios'

import Details from './Details'
import QRCode from './QRCode'
import SendSMS from './SendSMS'

import './AppDetails.css'

export const defaultBranding = {
  primary: '#00A898',
  secondary: '#FFC00E',
  background: '#FFFFFF',
  text: '#424242',
}

const AppDetails = ({ app }) => {
  // * component state for SMS modal
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleModal = e => {
    if (e) e.preventDefault()
    return setIsOpen(!isOpen)
  }

  let { name, description, id, Organization, branding, visibility } = app
  const url = window.location.href.split('?')[0]

  branding = branding || defaultBranding

  const handleCopyURL = e => {
    if (e) e.preventDefault()

    const value = document.createElement('input')
    document.body.appendChild(value)
    value.value = url
    value.select()
    document.execCommand('copy')
    document.body.removeChild(value)
  }

  const initialState = { phoneNumber: '', sending: false }
  const [sms, setSMS] = useState(initialState)
  const { phoneNumber, sending } = sms
  const handlePhoneNumberChange = e => {
    const { value } = e.target
    return setSMS({ ...sms, phoneNumber: value })
  }
  const handleSendSMS = async e => {
    e.preventDefault()

    setSMS({ ...sms, sending: true })

    const url = `${process.env.REACT_APP_BACKEND_URL}/apps/${id}/send-sms`
    const formatPhoneNumber = `+1${phoneNumber}`

    try {
      await axios.post(url, { phoneNumber: formatPhoneNumber, isFoundry: true })
      setSMS(initialState)
      return handleToggleModal()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="app-details-wrapper">
      <Details
        appId={id}
        name={name}
        description={description}
        Organization={Organization}
        branding={branding}
        visibility={visibility}
        handleToggleModal={handleToggleModal}
        handleCopyURL={handleCopyURL}
      />
      <QRCode
        url={url}
        handleToggleModal={handleToggleModal}
        handleCopyURL={handleCopyURL}
      />
      <SendSMS
        branding={branding}
        isOpen={isOpen}
        name={name}
        handleClose={handleToggleModal}
        handleChange={handlePhoneNumberChange}
        handleSubmit={handleSendSMS}
        value={phoneNumber}
        sending={sending}
      />
    </div>
  )
}

export default AppDetails
