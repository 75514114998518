import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import qs from 'qs'
import SafeAreaInsets from 'safe-area-insets'
import StatusBar from '@protonapp/react-status-bar'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames'

import {
  DATABASE_URL,
  DATABASE_API_URL,
  ASSETS_URL,
  FILE_UPLOADS_URL,
  IMAGE_UPLOADS_URL,
  NOTIFICATIONS_URL,
  WEBNOTIFICATION_VAPID_PUBLIC_KEY,
  WEBNOTIFICATION_FIREBASE_CONFIG,
} from '../config'

import { getAppIconUrl } from '../utils/io'
import { getLibraries } from '../utils/libraries'
import InstallPrompt from './InstallPrompt'
import LibraryLoader from './LibraryLoader'
import IOSInstallPrompt from './IOSInstallPrompt'
import Runner from './RunnerWrapper'
import { AppContext } from './App'

import '@protonapp/react-status-bar/styles.css'
import './Renderer.css'

const isMobile = window === window.top

const Renderer = props => {
  const [currentComponentId, setCurrentComponentId] = useState(null)

  /** @type {[import('beforeinstallprompt').BeforeInstallPromptEvent, (value: import('beforeinstallprompt').BeforeInstallPromptEvent) => void]} */
  const [installPrompt, setInstallPrompt] = useState(null)
  const [librariesReady, setLibrariesReady] = useState(false)
  const { app } = useContext(AppContext)
  const appId = app.id

  const webNotificationConfig = useMemo(
    () => ({
      vapidPublicKey: WEBNOTIFICATION_VAPID_PUBLIC_KEY,
      firebaseConfig: WEBNOTIFICATION_FIREBASE_CONFIG,
      appIcon: `${getAppIconUrl(appId)}?size=128`,
    }),
    [WEBNOTIFICATION_VAPID_PUBLIC_KEY, WEBNOTIFICATION_FIREBASE_CONFIG, appId]
  )

  const { hasResponsivePreviewer } = useFlags()

  const isResponsiveApp =
    app.primaryPlatform === 'responsive' && hasResponsivePreviewer

  const handleLoadLibraries = () => {
    setLibrariesReady(true)
  }

  const handleRouteChange = newRoute => {
    if (newRoute.componentId) {
      setCurrentComponentId(newRoute.componentId)
    }
  }

  const getParams = () => {
    return qs.parse(window.location.search.substring(1))
  }

  const getOS = () => {
    return getParams().os
  }

  const getLayoutGuides = () => {
    let params = getParams()

    if (SafeAreaInsets.top > 0) {
      return {
        top: SafeAreaInsets.top,
        bottom: 0,
      }
    }

    let offsetTop = params?.['offset_top'] ?? 0
    let offsetBottom = params?.['offset_bottom'] ?? 0

    if (isResponsiveApp) {
      const { offsetTop: newOffsetTop, offsetBottom: newOffsetBottom } = props

      offsetTop = newOffsetTop
      offsetBottom = newOffsetBottom
    }

    return {
      top: +offsetTop,
      bottom: +offsetBottom,
    }
  }

  let { offsetTop, analytics, previewType, activeDevice } = props

  const {
    offsetTop: newOffsetTop,
    previewType: newPreviewType,
    activeDevice: newActiveDevice,
  } = getParams()

  if (!offsetTop) {
    offsetTop = newOffsetTop
  }

  if (!previewType) {
    previewType = newPreviewType
  }

  if (!activeDevice) {
    activeDevice = newActiveDevice
  }

  /**
   *
   * @param {import('beforeinstallprompt').BeforeInstallPromptEvent} e
   */
  const onBeforeInstallPrompt = e => {
    e.preventDefault()

    if (e.type === 'beforeinstallprompt') {
      setInstallPrompt(e)
    }
  }

  useEffect(() => {
    // Not supported on:
    // - iOS Safari
    // - Firefox for Android
    // - Safari for macOS
    // - Firefox for desktop
    // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeinstallprompt_event
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt)
    }
  }, [])

  const renderStatusBar = () => {
    if (isMobile && !isResponsiveApp) {
      return null
    }

    if (!app || !app.components) {
      return null
    }

    let component = app.components[currentComponentId]
    let layoutGuides = getLayoutGuides()

    if (!component) {
      return null
    }

    const { statusBarStyle } = component

    if (statusBarStyle === 'hidden') {
      return null
    }

    const hasNotch = layoutGuides.top > 20

    if (activeDevice === 'desktop' || activeDevice === 'tablet') {
      return null
    }

    return (
      <div
        className={classNames('status-bar-wrapper', {
          'status-bar-wrapper--responsive': isResponsiveApp,
        })}
      >
        <StatusBar
          responsivePreviewer={isResponsiveApp}
          platform={getOS()}
          light={statusBarStyle === 'light'}
          hasNotch={hasNotch}
          device={activeDevice}
        />
      </div>
    )
  }

  let disableAnalytics = !analytics
  let layoutGuides = getLayoutGuides()
  let libraries = getLibraries(app)

  return (
    <Fragment>
      <LibraryLoader app={app} onLoad={handleLoadLibraries} />
      <Runner
        app={librariesReady ? app : null}
        baseURL={DATABASE_URL}
        baseAPIURL={DATABASE_API_URL}
        assetsBaseURL={ASSETS_URL}
        fileUploadsBaseURL={FILE_UPLOADS_URL}
        imageUploadsBaseURL={IMAGE_UPLOADS_URL}
        notificationsURL={NOTIFICATIONS_URL}
        transitionStyle={getOS()}
        onRoute={handleRouteChange}
        layoutGuides={layoutGuides}
        libraries={librariesReady && libraries}
        offsetTop={offsetTop}
        disableAnalytics={disableAnalytics}
        previewType={previewType}
        activeDevice={activeDevice}
        previewer
        webNotificationConfig={webNotificationConfig}
      />
      {renderStatusBar()}
      {installPrompt !== null && (
        <InstallPrompt app={app} deferredPrompt={installPrompt} />
      )}
      <IOSInstallPrompt app={app} />
    </Fragment>
  )
}

export default Renderer
