import React from 'react'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'
import qs from 'qs'

import Renderer from 'components/Renderer'
import PreviewType from 'utils/previewtype'
import { getAuthToken } from '../../utils/libraries'

import { ReactComponent as MobileFrameBlack } from './assets/mobile-frame-black.svg'
import { ReactComponent as TabletFrameBlack } from './assets/tablet-frame-black.svg'

const DEVICE_FRAMES = {
  [PreviewType.Mobile]: MobileFrameBlack,
  [PreviewType.Tablet]: TabletFrameBlack,
  [PreviewType.Desktop]: null,
}

const Frame = props => {
  const { appId, size, previewType, responsivePreviewer, activeDevice } = props

  const isDesktop = activeDevice === PreviewType.Desktop

  const getScale = () => {
    const { height } = size
    const margin = 30
    const windowHeight = Math.max(window.innerHeight, 400)
    const yPad = hasNotch() ? 20 : 90

    return (windowHeight - 2 * margin) / (height + 2 * yPad)
  }

  const getBaseURL = () => {
    return `/preview`
  }

  const hasNotch = () => {
    const { offset_top: offsetTop, os } = size

    return os === 'ios' && offsetTop > 20
  }

  const getURL = () => {
    const sessionToken = getAuthToken()

    const PREVIEW_BANNER_HEIGHT = 64

    const {
      width,
      height,
      os,
      offset_top: offsetTop,
      offset_bottom: offsetBottom,
    } = size

    const baseURL = getBaseURL()

    const params = {
      os,
      offset_bottom: offsetBottom,
      offset_top: offsetTop,
      offsetTop: PREVIEW_BANNER_HEIGHT,
      previewType,
      w: width,
      h: height,
      sessionToken,
    }

    const src = `${baseURL}/${appId}?${qs.stringify(params)}`

    return src
  }

  const getProps = () => {
    const sessionToken = getAuthToken()

    const {
      width,
      height,
      os,
      offset_top: offsetTop,
      offset_bottom: offsetBottom,
    } = size

    const props = {
      os,
      offsetBottom,
      offsetTop,
      width,
      height,
      sessionToken,
      responsivePreviewer,
      activeDevice,
      previewType: isDesktop ? PreviewType.Responsive : PreviewType.Mobile,
    }

    return props
  }

  const addNotch = hasNotch()
  const scale = getScale()
  const iframeURL = getURL()

  const { width, height } = size

  const styles = {
    transform: `scale(${scale})`,
    width: width + 40,
    height: height + (addNotch ? 40 : 180),
  }

  const innerStyles = {
    width: Math.ceil(scale * styles.width),
    height: Math.ceil(scale * styles.height),
  }

  if (responsivePreviewer) {
    const props = getProps()

    const DeviceFrame = DEVICE_FRAMES[activeDevice]

    const wrapperStyles = isDesktop
      ? { height: '100%' }
      : { width, height, transform: `scale(${scale})` }

    return (
      <div
        id={isDesktop ? 'responsive-preview-frame' : null}
        className={classNames('responsive-preview-frame-wrapper', {
          desktop: isDesktop,
        })}
      >
        <DocumentEvents target={window} />
        <div
          className="responsive-preview-frame-inner-wrapper"
          style={wrapperStyles}
        >
          {!isDesktop ? (
            <div className="device-frame">
              <DeviceFrame />
            </div>
          ) : null}
          <div
            id={!isDesktop ? 'responsive-preview-frame' : null}
            className={classNames({
              'responsive-preview-frame': !isDesktop,
              'responsive-preview-frame--desktop':
                activeDevice === PreviewType.Desktop,
              'responsive-preview-frame--mobile':
                activeDevice === PreviewType.Mobile,
              'responsive-preview-frame--tablet':
                activeDevice === PreviewType.Tablet,
            })}
          >
            <Renderer {...props} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="preview-frame-wrapper">
      <DocumentEvents target={window} />
      <div className="preview-frame-inner-wrapper" style={innerStyles}>
        <div
          className={classNames('preview-frame-border', { iphonex: addNotch })}
          style={styles}
        >
          <div className="preview-frame-clip">
            <iframe
              title="Frame"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              key={iframeURL}
              src={iframeURL}
              width={width}
              height={height}
              className="preview-frame"
              allow="camera *; microphone *"
            />
          </div>
          <div className="preview-frame-cover" />
        </div>
      </div>
    </div>
  )
}

export default Frame
