import React, { Component } from 'react'

export default class Attribution extends Component {
  render() {
    return (
      <a
        href="https://adalo.com"
        target="_blank"
        rel="noopener noreferrer"
        className="preview-proton-attribution"
      >
        <span className="logo" alt="Adalo" />
        <h6>Created with Adalo</h6>
      </a>
    )
  }
}
