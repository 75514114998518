import './utils/wdyr'

// Configure regeneratorRuntime first, so we don't error out
import './runtimeConfig'
import './rn-compatibility'
import 'core-js/stable'
import 'pwacompat'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import axios from 'axios'
import { initTracing } from '@protonapp/proton-runner'
import * as Sentry from '@sentry/react'

import { getAppIdUrl, getAppUrl } from 'utils/io'
import AppLoadError from 'utils/errors'

import Loading from 'components/Shared/Loading'
import NotFound from 'components/Share/NotFound'
import App from 'components/App'

import './global.css'

const routerHistory = createBrowserHistory()

initTracing(process.env.REACT_APP_SENTRY_DSN, {
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(routerHistory),
})

const regex = /\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b/g

async function main() {
  const appId = routerHistory.location.pathname.match(regex)

  let url

  if (appId) {
    url = getAppUrl(appId)
  } else {
    // Get App's id from host if it's a custom domain
    const host = window.location.host.split(':')[0]
    const query = `host=${encodeURIComponent(host)}`
    const appIdUrl = getAppIdUrl(query)
    const { data } = await axios.get(appIdUrl).catch(error => {
      console.error(
        `Error loading App ID: host:${host}, appIdUrl:${appIdUrl}`,
        error
      )
      Sentry.captureException(error)
    })
    url = getAppUrl(data.id)
  }

  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <div className="main-wrapper">
      <Loading expanded />
    </div>
  )

  const app = await axios
    .get(url)
    .then(({ data }) => data)
    .catch(error => {
      console.error(`Error loading App:`, error)
      Sentry.captureException(new AppLoadError(url, error))
    })

  if (!app) {
    return root.render(
      <div className="main-wrapper">
        <NotFound />
      </div>
    )
  }

  const { flagUser, name } = app

  document.title = name

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    user: {
      key: flagUser.key,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  }).catch(error => {
    console.error('Error loading LaunchDarkly:', error)
    Sentry.captureException(error)
  })

  root.render(
    <LaunchDarklyProvider>
      <App app={app} routerHistory={routerHistory} />
    </LaunchDarklyProvider>
  )
}

main()
