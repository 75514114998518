export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
export const DATABASE_URL = process.env.REACT_APP_DATABASE_URL
export const DATABASE_API_URL = process.env.REACT_APP_DATABASE_API_URL
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL
export const FILE_UPLOADS_URL = process.env.REACT_APP_FILE_UPLOADS_URL
export const IMAGE_UPLOADS_URL = process.env.REACT_APP_IMAGE_UPLOADS_URL
export const NOTIFICATIONS_URL = process.env.REACT_APP_NOTIFICATIONS_URL
export const WEBNOTIFICATION_VAPID_PUBLIC_KEY =
  process.env.REACT_APP_WEBNOTIFICATION_VAPID_PUBLIC_KEY
export const WEBNOTIFICATION_FIREBASE_CONFIG = JSON.parse(
  process.env.REACT_APP_WEBNOTIFICATION_FIREBASE_CONFIG
)
