import React, { useState } from 'react'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import Button from 'components/Shared/Button'
import { FRONTEND_URL } from '../../../config'
import AppIcon from '../Icon'

const Details = ({
  appId,
  branding,
  visibility,
  name,
  description,
  Organization,
  handleToggleModal,
  handleCopyURL,
}) => {
  const options = [
    { label: 'Copy Link', onClick: handleCopyURL },
    { label: 'Send Link via SMS', onClick: handleToggleModal },
  ]
  const [inHover, setHover] = useState(false)

  const cloneAppBtnStyles = inHover
    ? {
        color: 'white',
        borderColor: branding.primary,
        backgroundColor: branding.primary,
      }
    : {
        color: branding.primary,
        borderColor: branding.primary,
      }

  return (
    <div className="app-details">
      <section className="app-details-header">
        <AppIcon appId={appId} name={name} />
        <div className="app-details-header-text">
          <h1 title={name}>{name}</h1>
          <p title={`By ${Organization && Organization.name}`}>
            By {Organization && Organization.name}
          </p>
        </div>
      </section>
      {description && (
        <section className="app-details-body">
          <p>{description}</p>
        </section>
      )}
      <section className="app-details-actions">
        <div>
          {visibility === 'screens' || visibility === 'data' ? (
            <div className="details-clone-button-wrapper">
              <a
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={cloneAppBtnStyles}
                className="details-clone-button"
                href={`${FRONTEND_URL}/apps/${appId}/screens/${visibility}`}
              >
                Clone App
              </a>
            </div>
          ) : null}
        </div>
        <MultiMenuTrigger menu={options} rowHeight={40} hideCarret>
          <Button backgroundColor={branding.primary}>Share App</Button>
        </MultiMenuTrigger>
      </section>
    </div>
  )
}

export default Details
