import React from 'react'
import { withRouter } from 'react-router-dom'

const Wrapper = props => {
  const { children } = props

  return (
    <div className="app">
      {children}
      <div className="app-border" />
    </div>
  )
}

export default withRouter(Wrapper)
